import { defineNuxtRouteMiddleware, navigateTo, useCookie } from '#app';
import { sessionTokenKey } from '~/types/session';
import { urlBeforeLoginKey } from '~/types/url-before-login';

export default defineNuxtRouteMiddleware((to, from) => {
  const cookie = useCookie(sessionTokenKey);

  if (cookie.value) {
    const urlToRedirect = useCookie(urlBeforeLoginKey);

    return navigateTo(urlToRedirect.value || '/');
  }
});
